<template>
    <layout-default>
        <div class="row align-items-start justify-content-center">
            <div class="col-md-8">
                <div class="back">
                    <router-link to="/app/wallet"><i class="material-icons">chevron_left</i> Back to Wallet</router-link>
                </div>
                <div class="card transaction">
                    <h4 class="transaction-hash-title">Transaction Hash</h4>
                    <div class="transaction-hash">
                        <Clipboard ellipsize :clipboard="hash" :link="explorerLink"/>
                    </div>

                    <h4>Summary</h4>
                    <div class="info-table summary">
                        <div class="info-row">
                            <span class="label">Timestamp</span>
                            <span class="value value-timestamp"></span>
                        </div>
                        <div class="info-row">
                            <span class="label">From</span>
                            <span class="value"><Clipboard :address="from"/></span>
                        </div>
                        <div class="info-row">
                            <span class="label">Contract</span>
                            <span class="value value-contract"></span>
                        </div>
                        <div class="info-row">
                            <span class="label">Function</span>
                            <span class="value value-function"></span>
                        </div>
                        <div class="info-row">
                            <span class="label">Parameters</span>
                            <span class="value value-parameters"></span>
                        </div>
                    </div>

                    <h4>Events</h4>
                    <div class="events-wrapper">
                    </div>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script>
    import Clipboard from "@/components/Clipboard";
    import { getChain } from "@blockwell/chains";
    import {mapGetters} from "vuex";
    import * as util from '../../assets/lib/util';
    import Api from '../../lib/api/WalletApi';
    import * as moment from 'moment';
    const api = new Api('legacy');

    export default {
        components: {Clipboard},
        data() {
            return {
                hash: "",
                from: "",
                network: null
            }
        },
        computed: {
            ...mapGetters('user', ['api']),
            explorerLink() {
                return getChain(this.network).explorerTx(this.hash);
            }
        },
        mounted() {
            let path = window.location.pathname.split('/');
            let id = path[path.length - 1];
            this.api.getTransaction(id)
                .then(tx => this.renderTransaction(tx))
                .catch(console.error);
        },
        methods: {
            async renderTransaction(txn) {
                let name = "No Contract";
                let decimals = 18;
                let symbol;
                if (txn.contractId) {
                    let token = await this.api.getTokenData(txn.contractId);

                    name = token.name;
                    decimals = token.decimals;
                    symbol = token.symbol;
                }
                let time = moment(txn.created).format('MMM D YYYY, h:mm a');

                this.hash = txn.transactionHash;
                this.from = txn.from;
                this.network = txn.network;
                $('.value-timestamp').text(time);
                $('.value-contract').text(name);
                $('.value-function').text(txn.method);
                $('.value-parameters').html(txn.parameters.join('<br>'));

                if (txn.events && txn.events.length > 0) {
                    $('.events-wrapper').append(txn.events.map(it => {
                        let values = Object.entries(it.returnValues).map(([key, val]) => {
                            let value = val;
                            if (key === 'value') {
                                let converted = util.toDecimals(txn.events[0].returnValues.value, decimals);
                                value = `${converted} ${symbol}`;
                            }
                            return `
                    <div class="info-row">
                        <span class="label">${key}</span>
                        <span class="value clipboard" data-clipboard-name="${key}">${value}</span>
                    </div>`
                        }).join('');

                        return $(`
                <h5>${it.event}</h5>
                <div class="info-table">
                    ${values}
                </div>
            `)
                    }));
                }
            }
        }
    }

</script>

<style lang="scss">
    @media screen and (max-width: 460px) {
        .events-wrapper .label {
            font-size: 0.8em;
        }
    }

    .transaction .transaction-hash {
        .clipboard-wrap {
            display: flex;
        }
        .clipboard-text {
            max-width: none;
            flex: 0 1 auto;
        }
    }
</style>
